.card {
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    background-color: #010514; /* Dark background for the card */
    /*background-color: #2a2d3a; !* Dark background for the card *!*/
    color: #fff; /* White text color */
    /*padding: 2rem; !* Padding inside the card *!*/
    border-radius: 25px; /* Rounded corners for the card */
    text-align: center; /* Center the text */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow for depth */
    box-sizing: border-box;
}

.card:hover {
    /*transform: translateY(-100px); !* Поднимает карточку на 10 пикселей вверх *!*/
    /*background-color: #2a2d3a;*/
    /*background-color: #02024b;*/
    scale: 107%;
    cursor: help;
    z-index: 999;
}

.frontFace {
    background-color: #1c1cad; /* Dark background for the card */
    color: #fff; /* White text color */
    padding: 2rem; /* Padding inside the card */
    border-radius: 25px; /* Rounded corners for the card */
    text-align: center; /* Center the text */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow for depth */
    box-sizing: border-box;
}


.backFace {
    max-width: 1100px;

    width: 90%;
    /*height: 100%;*/
    /*position: absolute;*/
    backface-visibility: hidden;
    transform-style: preserve-3d;
    visibility: hidden;

    /*background-color: #1f1f47; !* Dark background for the card *!*/
    /*color: #fff; !* White text color *!*/
    /*padding: 2rem; !* Padding inside the card *!*/
    border-radius: 25px; /* Rounded corners for the card */
    /*text-align: center; !* Center the text *!*/
    /*box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); !* Shadow for depth *!*/
    /*box-sizing: border-box;*/
    border-radius: 10px;
    padding: 24px;
    box-shadow: none;
    background: rgb(35, 35, 40);

}


.cards {
    /*perspective: 1000px;*/
    width: 200px; /* или ваша желаемая ширина */
    /*height: 300px; !* или ваша желаемая высота *!*/
    /*position: relative;*/
    margin-bottom: 3px;
}


.overlay {
    /* Стили для оверлея, который покрывает весь экран */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5); /* Прозрачный фон */
}

.close-button {
    /* Стили для кнопки закрытия */
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    /* ... */
}

.icon {
    width: 200px;
    border-radius: 10%;
    /*margin: -2rem -2rem 0 -2rem;*/

}

.botIcon {
    width: 200px;
    border-radius: 10%;
    /*margin: 0 -2rem -2rem -2rem;*/

}

.title {
    font-size: 1.3rem; /* Title font size */
    margin: 0.5rem; /* Space below the title */
    /*color: #aafbfd;*/
    /* font-family: 'Brush Script MT', sans-serif; !* Пример шрифта *!*/
    text-transform: uppercase; /* Для стиля текста заглавными буквами */

    font-family: 'Comic Sans MS', sans-serif; /* Используйте подходящий шрифт */
    /*font-size: 24px; !* Или любой другой размер *!*/
    /*font-weight: bold; !* Для жирности текста *!*/
    color: transparent; /* Делаем текст прозрачным, чтобы показать градиент */
    background-image: linear-gradient(100deg, #1e5799, #aafbfd, #3ed0d3, #4f6483); /* Пример градиента */
    background-clip: text; /* Обрезаем фон по тексту */
    -webkit-background-clip: text; /* Для поддержки вебкит-браузеров */
    display: inline-block; /* Чтобы свойства background-clip работали */
}

.content {
    height: 155px;
}

.description {
    font-size: 1rem; /* Description font size */
    line-height: 1.5; /* Line height for better readability */
}

.maintenance {
    background-image: url('../../assets/dev_1.webp'); /* Путь к вашему изображению */
    background-size: cover; /* Обеспечивает покрытие всего div изображением */
    background-position: center; /* Центрирует изображение в div */
    background-repeat: no-repeat; /* Не повторяет изображение */
    height: 535px; /* Высота div, настраивается по вашему усмотрению */
    display: flex; /* Использует Flexbox для выравнивания содержимого */
    align-items: baseline; /* Вертикальное выравнивание текста по центру */
    justify-content: center; /* Горизонтальное выравнивание текста по центру */
    text-align: center; /* Выравнивание текста по центру */
    color: white; /* Цвет текста, может быть изменен в соответствии с изображением */
    font-size: 24px; /* Размер шрифта */

    border-radius: 2%;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.mContent {
    padding: 37px;
    background-color: rgba(0, 0, 0, 0.5); /* Полупрозрачный фон за текстом */
    /*padding: 10px 20px; !* Отступы вокруг текста *!*/
    border-radius: 15px; /* Скругление углов фонового блока */
    display: inline-block; /* Блок будет соответствовать размерам контента */
    margin: 20px 20px 0;
}

/*.mContent {*/
/*    background-color: rgba(255, 255, 255, 0.8); !* Светлый полупрозрачный фон для улучшения читаемости *!*/
/*    color: #333; !* Темно-серый цвет текста для контраста *!*/
/*    padding: 20px;*/
/*    border-radius: 10px; !* Скругленные углы *!*/
/*    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); !* Легкая тень для эффекта "всплывания" *!*/
/*    max-width: 80%; !* Ограничение ширины для больших экранов *!*/
/*}*/

.mContent h1 {
    color: #b792d7;
    font-family: "Yamaha EBM7 Bold", sans-serif; /* Шрифт текста */
    font-size: 28px; /* Большой размер для заголовка */
    margin-bottom: 10px; /* Отступ между заголовком и текстом */
}

.mContent p {
    color: #cee7cc;
    font-family: "Yamaha EBM7 Bold", sans-serif; /* Шрифт текста */
    font-size: 18px; /* Размер для подзаголовка */
}

.mContent strong {
    color: #a7d9c8;
}