/* Footer.css */
.footer {
  padding: 20px;
  background-color: #0a0a23; /* Темный фон */
  color: #fff; /* Белый текст */
  text-align: center;
}

.footer-content p {
  margin: 0;
  padding: 5px;
  font-size: 14px;
}

/* Вы можете добавить дополнительные стили для адаптивности и тематического оформления */
