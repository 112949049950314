.faq {
  background-color: #0a0a23; /* Dark blue background */
  color: #ffffff; /* White text */
  /*padding: 4rem 0; !* Top and bottom padding *!*/
  height: 444px;
}

.container {
  max-width: 1200px; /* Max width of the content */
  margin: 0 auto; /* Center the content */
  padding: 0 2rem; /* Padding on the sides */
}

.title {
  font-size: 2.5rem; /* Large font size for the title */
  margin-bottom: 1rem; /* Space below the title */
}

.description {
  font-size: 1.2rem; /* Font size for the description text */
  line-height: 1.6; /* Line height for readability */
}


.maintenance {
    background-image: url('../../assets/dev_3.webp'); /* Путь к вашему изображению */
    background-size: cover; /* Обеспечивает покрытие всего div изображением */
    background-position: center; /* Центрирует изображение в div */
    background-repeat: no-repeat; /* Не повторяет изображение */
    height: 535px; /* Высота div, настраивается по вашему усмотрению */
    display: flex; /* Использует Flexbox для выравнивания содержимого */
    align-items: baseline; /* Вертикальное выравнивание текста по центру */
    justify-content: center; /* Горизонтальное выравнивание текста по центру */
    text-align: center; /* Выравнивание текста по центру */
    color: white; /* Цвет текста, может быть изменен в соответствии с изображением */
    font-size: 24px; /* Размер шрифта */

    border-radius: 2%;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.mContent {
    padding: 37px;
    background-color: rgba(0, 0, 0, 0.5); /* Полупрозрачный фон за текстом */
    /*padding: 10px 20px; !* Отступы вокруг текста *!*/
    border-radius: 15px; /* Скругление углов фонового блока */
    display: inline-block; /* Блок будет соответствовать размерам контента */
    margin: -140px 20px 0;
    z-index: 999;
}

/*.mContent {*/
/*    background-color: rgba(255, 255, 255, 0.8); !* Светлый полупрозрачный фон для улучшения читаемости *!*/
/*    color: #333; !* Темно-серый цвет текста для контраста *!*/
/*    padding: 20px;*/
/*    border-radius: 10px; !* Скругленные углы *!*/
/*    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); !* Легкая тень для эффекта "всплывания" *!*/
/*    max-width: 80%; !* Ограничение ширины для больших экранов *!*/
/*}*/

.mContent h1 {
    color: #b792d7;
    font-family: "Yamaha EBM7 Bold", sans-serif; /* Шрифт текста */
    font-size: 28px; /* Большой размер для заголовка */
    margin-bottom: 10px; /* Отступ между заголовком и текстом */
}

.mContent p {
    color: #cee7cc;
    font-family: "Yamaha EBM7 Bold", sans-serif; /* Шрифт текста */
    font-size: 18px; /* Размер для подзаголовка */
}

.mContent strong {
   color: #a7d9c8;
}