body {
    background-color: #251f48; /* фиолетовый цвет фона */
    /*background-color: #261846; !* фиолетовый цвет фона *!*/
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden;

    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}
