.about {
  background-color: #0a0a23; /* Dark blue background */
  color: #ffffff; /* White text */
  padding: 4rem 0; /* Top and bottom padding */
  /*height: 300px;*/
}

.container {
  max-width: 1200px; /* Max width of the content */
  margin: 0 auto; /* Center the content */
  padding: 0 2rem; /* Padding on the sides */
}

.title {
  font-size: 2.5rem; /* Large font size for the title */
  margin-bottom: 1rem; /* Space below the title */
}

.title_ {

  font-size: 1.5rem; /* Large font size for the title */
  /*margin-bottom: 1rem; !* Space below the title *!*/
   margin: 1rem 2rem 0.5rem 2.8rem;
}

.description {
  font-size: 1.2rem; /* Font size for the description text */
  line-height: 1.6;
  margin: 0 2rem 0 2rem;/* Line height for readability */
}
