:root {
    --radix-collapsible-content-height: 0px;
    --radix-collapsible-content-width: 100%;
    color: rgb(255, 255, 255);
}

/*body {*/
/*    margin: 0;*/
/*    color: rgb(255, 255, 255);*/
/*    font-family: Inter, Poppins, Roboto Mono, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";*/
/*    font-weight: 400;*/
/*    font-size: 16px;*/
/*    line-height: 19px;*/
/*    letter-spacing: 0;*/
/*    text-transform: none;*/
/*    background-color: rgb(23, 23, 29);*/
/*}*/

@media (min-width: 1280px) {
    .cardBoarder {
        max-width: 1280px;
    }
}

@media (min-width: 600px) {
    .cardBoarder {
        padding: 0 24px;
    }
}

.cardBoarder {
    width: 100%;
    margin-left: auto;
    box-sizing: border-box;
    margin-right: auto;
    display: block;
    padding: 10px 16px 32px;
}

.cardWindow {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.stakeTotalBoarder {
    border-radius: 10px;
    padding: 16px;
    box-shadow: none;
    background: rgb(35, 35, 40);
}

.stakeTotalBoarderrWindow {
    display: flex;
    flex-wrap: wrap;
    -moz-box-align: center;
    align-items: center;
    -moz-box-pack: justify;
    justify-content: space-between;
    gap: 16px;
    padding-left: 8px;
    padding-right: 8px;
}


.stakeRData {
    display: flex;
    flex-wrap: nowrap;
    gap: 32px;
    -moz-box-pack: center;
    justify-content: center;
}


.stakeRData1 {
    display: flex;
    -moz-box-align: center;
    align-items: center;
    gap: 16px;
}

@media (min-width: 0px) {
    .stakeRData1 {
        flex-direction: column;
        width: 100%;
        -moz-box-pack: center;
        justify-content: center;
    }
}


@media (min-width: 50px) {
    .stakeRData1 {
        flex-direction: row;
        width: 100%;
        -moz-box-pack: start;
        justify-content: flex-start;
    }
}

@media (min-width: 400px) {
    .stakeRData1 {
        flex-direction: row;
        width: auto;
        -moz-box-pack: start;
        justify-content: flex-start;
    }
}

.divider-vertical {
    margin: 0;
    flex-shrink: 0;
    border-width: 0 thin 0 0;
    border-style: solid;
    height: 100%;
    border-color: rgba(255, 255, 255, 0.1);
}

.stakeRDataTitle {
    margin: 0px;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0px;
}

.stakeRDataValue {
    margin: 0;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
}

.stakeAccounts {
    border-radius: 10px;
    padding: 24px;
    box-shadow: none;
    background: rgb(35, 35, 40);
}

.stakeAccountsHead {
    display: flex;
    -moz-box-pack: justify;
    justify-content: space-between;
    -moz-box-align: center;
    align-items: center;
    padding-bottom: 24px;
}

.stakeAccountsHeader {
    margin: 0;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
}

.delete {
    display: flex;
    -moz-box-align: center;
    align-items: center;
}


.stakeAccountsBorder {
    padding-top: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    /*border-bottom-color: rgba(255, 255, 255, 0.1);*/
    /*border-color: rgba(255, 255, 255, 0.1);*/
}

.stakeHeadTable {
    display: grid;
    -moz-box-align: center;
    align-items: center;
    grid-template-columns: auto 165px 145px 145px 20px;
    padding: 0 16px;
}

.stakeHeadTableF1 {
    margin: 0;
    font-size: 14px;
    flex: 1 1 0;
    color: rgba(255, 255, 255, 0.4);
    line-height: 17px;
}


.stakeHeadTableF2 {
    margin: 0;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.4);
    line-height: 17px;
}

.stakeAccountsRow {
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}


.accordion[data-state=open] .accordion {
    transform: rotate(180deg);
}

.stakeTableFlex {
    display: flex;
    justify-content: center;
}

.stakeStatusV {
    display: flex;
    gap: 8px;
}

.stakeRow {
    padding: 0px;
    margin: 0px;
    border: medium;
    background: none;
    flex: 1 1 0%;
    cursor: pointer;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.stakeAccountsRowGrid {
    display: grid;
    -moz-box-align: center;
    align-items: center;
    grid-template-columns: auto 165px 145px 145px 20px;
    padding: 20px 16px;
}

.stakeAccountsCol1 {
    display: flex;
    gap: 20px;
    flex: 1 1 0%;
    -moz-box-align: center;
    align-items: center;
    overflow: hidden;
    padding-right: 8px;
}

.StakeAccAva {
    flex-shrink: 0;
    display: inline-flex;
    vertical-align: middle;
    overflow: hidden;
    height: 40px;
    width: 40px;
    background-color: rgba(255, 255, 255, 0.06);
    color: #ffffff;
    border-radius: 100px;
    justify-content: center;
}

.validatorAv {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: inherit;
}

.stakeStatus {
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 16px;
    text-transform: none;
}

.stakeStatusUnstakeColor {
    color: #FF842D;
    background-color: rgba(255, 132, 45, 0.05);
}

.stakeStatusActivColor {
    color: #2D8BFA;
    background-color: rgba(45, 139, 250, 0.08);
}

.tag_tagRecipe_variant_success__c80ptw3 {
    color: #3dd28b;
    background-color: rgba(61, 210, 139, 0.08);
}

.stValidatorFont {
    margin: 0;
    font-family: Inter, Poppins, Roboto Mono, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0px;
    text-transform: none;
    color: white;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.stInfoFont {
    margin: 0px;
    font-family: Inter, Poppins, Roboto Mono, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0px;
    text-transform: none;
    color: white;
    text-align: left;
}

.accordionContent[data-state=open] {
    animation: accordion_slideDown__1jqaf4i1 250ms cubic-bezier(0.87, 0, 0.13, 1);
}

.accordionContent[data-state=closed] {
    animation: accordion_slideUp__1jqaf4i2 250ms cubic-bezier(0.87, 0, 0.13, 1);
}
