.featuresSection {
    padding: 0 0 1rem; /* Top and bottom padding */
    /*background-color: #101036; !* Matching the space theme *!*/
}

.container {
    max-width: 1000px;
    margin: 0 auto; /* Centering the cards */
    display: flex;
    flex-wrap: wrap; /* Allows multiple rows of cards */
    /*gap: 0.1rem; !* Space between the cards *!*/
    justify-content: center; /* Center cards in the container */
}
.bannerButton{

    position: absolute;
    margin-top: -50px;
    margin-left: 800px;


}
/* Responsive design for smaller screens */
@media (max-width: 768px) {
    /*.container {*/
    /*    flex-direction: column; !* Stack the cards vertically on small screens *!*/
    /*}*/
}
