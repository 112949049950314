/* Header.module.css */
.headerContainer {
    color: #fff;
    padding: 0.5rem 0 0.1rem 0 ;
    display: flex;
    justify-content: space-between;
    align-items: center;


    width: 100%;
    max-width: 1200px;

    position: absolute;
    top: 0;
    z-index: 666;
}


.logo {
    margin: 0 1rem 0 3rem;
    font-size: 2rem;
}

.nav {
    margin-right: 0.1rem;
}

.nav ul {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
}

.nav li {
    margin-left: 2rem;
}

.nav a {
    color: #fff;
    text-decoration: none;
    transition: color 0.3s;
}

.nav a:hover {
    color: #7f53ac; /* Пурпурный цвет при наведении */
}

.wallet_button {
  background-color: #00BFFF; /* Цвет фона кнопки */
  color: white; /* Цвет текста */
  border: none; /* Убираем границу */
  padding: 3px 20px; /* Внутренние отступы */
  border-radius: 20px; /* Скругляем углы */
  font-weight: bold; /* Жирный шрифт текста */
  cursor: pointer; /* Курсор в виде указателя */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Тень для 3D эффекта */
  text-transform: uppercase; /* Текст заглавными буквами */
    margin-right: 1rem;
}

.wallet_button:hover {
  background-color: #0099CC; /* Цвет фона при наведении */
}
