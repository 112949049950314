.mainBanner {
    /*background-color: #131339; !* Dark space-themed background color *!*/
    color: #fff; /* White text color */
    /*padding: 5rem 1.5rem; !* Padding around the content *!*/
    position: absolute; /* For positioning elements within the banner */
    text-align: center; /* Center the text */
    /*height: 206px;*/
    /*transform: translate(64%, -107%);*/
    /*top: 135px;*/
    /*right: 200px;*/

      transform: scale(0.8);
}

.bannerContent {
    max-width: 1200px;
    margin: 0 auto; /* Center the banner content */
}

.bannerTitle {
    font-size: 4.2rem; /*Large font size for the main title */
    margin-bottom: 3rem; /* Space below the title */
      /*font-family: 'Yamaha EBM7 Bold', sans-serif; !* Используйте подходящий шрифт *!*/
      font-family: 'KidpixiesRegular', sans-serif; /* Используйте подходящий шрифт */
    /*font-size: 24px; !* Или любой другой размер *!*/
    /*font-weight: bold; !* Для жирности текста *!*/
    color: transparent; /* Делаем текст прозрачным, чтобы показать градиент */

    background-image: linear-gradient(100deg, #0b1de8, #aafbfd, #3ed0d3, #4f6483); /* Пример градиента */
    background-clip: text; /* Обрезаем фон по тексту */
    -webkit-background-clip: text; /* Для поддержки вебкит-браузеров */
    /*display: inline-block; !* Чтобы свойства background-clip работали *!*/
}

.bannerSubtitle {
    font-size: 1.5rem;
    margin-bottom: 1.2rem;
    font-family: 'Rockwell', sans-serif; /* Используйте подходящий шрифт */
    /*font-size: 24px; !* Или любой другой размер *!*/
    /*font-weight: bold; !* Для жирности текста *!*/
    color: transparent; /* Делаем текст прозрачным, чтобы показать градиент */
    /*background-image: linear-gradient(100deg, #a624ca, #594145, #3a7273, #34cc73);*/
    background-image: linear-gradient(100deg, #acd5bd, #53dbde, #3ed0d3, #acd5bd); /* Пример градиента */
    background-clip: text; /* Обрезаем фон по тексту */
    -webkit-background-clip: text; /* Для поддержки вебкит-браузеров */
    /*display: inline-block; !* Чтобы свойства background-clip работали *!*/
}

.bannerImage {
    width: 100%; /* Full width */
    /*max-width: 600px; !* Maximum width of the image *!*/
    height: auto; /* Keep the aspect ratio */
    margin-bottom: 2rem; /* Space below the image */
}

.bannerButton {
    padding: 1rem 2rem; /* Padding inside the button */
    font-size: 1.2rem; /* Button font size */
    color: #acd5bd; /* White text color */
    /*font-weight: bold;*/
    /* font-family: 'Yamaha EBM8 Regular', sans-serif;*/
     font-family: 'KidpixiesRegular', sans-serif;
    background-color: #5c01ff; /* Button background color */
    border: none; /* No border */
    border-radius: 25px; /* Rounded corners */
    cursor: pointer; /* Cursor indicates clickable button */
    transition: background-color 0.3s; /* Transition for a hover effect */
}

.bannerButton:hover {
    background-color: #7d3cf4; /* Slightly lighter purple background on hover */
}