.contact {
  background-color: #0a0a23; /* Dark blue background */
  color: #ffffff; /* White text */
  padding: 4rem 0; /* Top and bottom padding */
  height: 300px;
}

.container {
  max-width: 1200px; /* Max width of the content */
  margin: 0 auto; /* Center the content */
  padding: 0 2rem; /* Padding on the sides */
}

.title {
  font-size: 2.5rem; /* Large font size for the title */
  margin-bottom: 1rem; /* Space below the title */
}

.description {
  font-size: 1.2rem; /* Font size for the description text */
  line-height: 1.6; /* Line height for readability */
}

  .contactInfo {
    /*background-color: #fff;*/
    border-radius: 8px;
    padding: 20px;
    margin: 20px auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: inline-block;
    text-align: left;
  }
  .contactInfo h2 {
    color: #e5efe4;
  }
  .contactLink {
    color: #007bff;
    text-decoration: none;
  }
  .contactLink:hover {
    text-decoration: underline;
  }