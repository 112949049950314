@font-face {
    font-family: 'KidpixiesRegular';
    src:
         url('./assets/kidpixies-font/KidpixiesRegular-p0Z1.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Yamaha EBM7 Bold.ttf';
    src:
         url('./assets/kidpixies-font/Yamaha EBM7 Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'BULKYPIX';
    src:
         url('./assets/kidpixies-font/BULKYPIX.TTF') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'FailedAttempt';
    src:
         url('./assets/kidpixies-font/Failed Attempt.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'joystix monospace';
    src:
         url('./assets/kidpixies-font/joystix monospace.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'pcsenior';
    src:
         url('./assets/kidpixies-font/Failed Attempt.otf') format('pcsenior');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'PressStart2P-Regular';
    src:
         url('./assets/kidpixies-font/PressStart2P-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'prstart';
    src:
         url('./assets/kidpixies-font/prstart.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'prstartk';
    src:
         url('./assets/kidpixies-font/prstartk.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'yoster';
    src:
         url('./assets/kidpixies-font/yoster.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}


.header-container {
    margin: 0 auto;
    position: relative;
    display: inline-block;
    max-height: 480px;
    height: 100%;
    width: 100%;
    max-width: 1200px;
}

.bannerImg {
    max-width: 100%;
    height: auto;
    position: relative;
}

.header-container img,
.container section {
    transform: scale(1);

}

.App {
    max-width: 1200px;
    margin: 0 auto;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}